import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import EventList from "../../components/EventList";
import Event from "../../components/Event";

function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/event/:occurrenceId">
          <Event />
        </Route>
        <Route path="/">
          <EventList />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
