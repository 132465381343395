import React from "react";
import "./App.css";
import Router from "./routing/Router";
import Provider from "./context";

function App({url}) {
  return (
    <Provider url={url}>
      <Router />
    </Provider>
  );
}

export default App;
