import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, Link } from "react-router-dom";
import formatDate from "../../utils/formatDate";

function EventImage({ url }) {
    const [result, setResult] = useState({});
    const [error, setError] = useState({});
    useEffect(() => {
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setResult(result);
                },
                (error) => {
                    setError(error);
                }
            )
        }, [url])
    if(!error) {
        return (
            <img src={result.url} alt={result.title}></img>
        )
    } else {
        return null;
    }
}

function Event() {
    const [data, setData] = useState([]);
    const [hasLoaded, setHasLoaded] = useState(null);
    const [error, setError] = useState([]);
    let { occurrenceId } = useParams();

    useEffect(() => {
        fetch("https://medarbeideren.feed.dkmnet.dk/events/8366")
            .then(res => res.json())
            .then(
                (result) => {
                    setData(result.filter(obj => {
                        return obj.OccurrenceId === occurrenceId;
                    }));
                    setHasLoaded(true);
                },
                (error) => {
                    setError(error);
                }
            )
        }, [occurrenceId])

        if(hasLoaded) {
            return (
                <>
                <BackLink to="/">Tilbage</BackLink>
                <EventTitle>{data[0]?.Title}</EventTitle>
                <EventImage url={data[0]?.ImageURL}></EventImage>
                <EventDate>{formatDate(data[0]?.StartDateTime, data[0]?.EndDateTime)}</EventDate>
                <EventLocation>{data[0]?.Location}</EventLocation>
                </>
            )
        } else {
            return <div>Loading...</div>;
        }
}

const EventTitle = styled.div`
    font-size: 180%;
    margin: 1em 0;
`;

const EventLocation = styled.div`
    padding: 0 0 0 1em;
`;

const EventDate = styled.div`
    font-weight: bold;
    padding: 0 0 0 1em;
`;

const BackLink = styled(Link)`
    color: rgb(181, 74, 16);
`; 

export default Event;